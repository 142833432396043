import { Link } from 'react-router-dom';
import logo from "../assets/logo.svg";
import { useTranslation } from "react-i18next";

const Menu = (appConfig: AppConfig) => {
    const { t, i18n } = useTranslation();
    const email = appConfig?.userAttributes?.employeeEmail;
    const firstName = email?.substring(0, email?.indexOf(".")) + " ";
    const lastName = email?.substring(email?.indexOf(".") + 1, email?.indexOf("@"))
    const userName = firstName + " " + lastName;

    return (
        <div id="content-wrap " style={{
            position: "relative",
            minHeight: "100%",
            borderBottom: "solid 1px #dde3e7",
            textDecoration: "none"
        }}>
            <div className=''>

                <header className="navigation-swisscom p-1 nav__level1" role={"navigation"} id="topNav">
                    <nav className={"nav nav__level1"}>
                        <div className={"container-fluid "}>
                            <a href="/" className={"logo left"} id="logo" style={{ marginLeft: "80px" }} tabIndex={-1}>
                                <span 
                                    className={"logo-lifeform"}
                                    aria-label={"Swisscom Logo"}
                                    style={{ display: "inline" }}
                                />
                                <span
                                    className={"left logo-type"}
                                    style={{ display: "inline" }}>
                                </span>
                                <span className="logo-type"><img src={logo} alt="Swisscom" /></span>

                            </a>
                            <nav className='scs-pageheader py-4   navigation '>
                                <div className="d-inline mr-2" id="username" style={{ marginRight: "60px" }}>
                                    <Link style={{ textDecoration: "none" }} to={'/'}><span style={{ fontSize: "22px" }} className="sc-navy pt-5">{t('menu.SUMMARY')}</span></Link>
                                </div>
                                <div className="d-inline mr-2" id="username" style={{ marginRight: "60px" }}>
                                    <Link to="/transactions" style={{ textDecoration: "none" }}> <span style={{ fontSize: "22px" }} className="sc-navy pt-5">{t('menu.TRANSACTIONS')}</span>

                                    </Link>
                                </div>
                                <div className="default hydrated sc-navy float-end">
                                    <div className="d-inline mr-2" id="username" style={{ marginRight: "50px" }}>
                                        <sdx-icon icon-name="icon-person" colorClass='sc-navy' style={{ paddingRight: "10px" }} size={2} sr-hint="Displays the user account"></sdx-icon>
                                        <span style={{ fontSize: "20px" }} className="sc-navy py-2">{userName}</span>
                                    </div>
                                    <sdx-menu-flyout style={{ marginRight: "30px" }}>
                                        <select defaultValue={i18n.language.split("-")[0]} onChange={(e: any) => i18n.changeLanguage(e.target.value)} style={{ border: "none" }} className="form-select" aria-label="Default select">
                                            <option value="en">EN</option>
                                            <option value="de">DE</option>
                                            <option value="fr">FR</option>
                                            <option value="it">IT</option>
                                        </select>
                                    </sdx-menu-flyout>
                                </div>
                            </nav>
                        </div>
                    </nav>
                </header>
            </div>
        </div>
    )
}

export default Menu;