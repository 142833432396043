import { useState } from 'react';
import AjaxCall from '../components/AjaxCall';
import Format from '../components/Format';
import Message from '../components/Message';
import { useTranslation } from 'react-i18next';

const BalanceTable = (appConfig: AppConfig) => {
    const [balanceResponse, setBalanceResponse] = useState<BalanceResponse>();
    const [errorResponse, setErrorResponse] = useState<any>();
    const { t } = useTranslation();

    AjaxCall({
        url: appConfig.balanceUrl, 
        params: appConfig.userAttributes?.employeeId ? {employeeId: appConfig.userAttributes.employeeId} : {},
        appConfig: appConfig,
        successHandler: (response: any) => setBalanceResponse(response.data),
        errorHandler: (error: any) => setErrorResponse(error.response ? (error.response.data ? error.response.data : error.response) : error)
    });

    const renderBalanceTable = () => (
        <table className="table">
            <thead>
                <tr>
                    <th>{t('summary.BALANCE')}</th>
                    <th><Format value={balanceResponse?.summary?.balance} currency="CHF" /></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{t('summary.EMPLOYEE_ID')}</td>
                    <td>{balanceResponse?.employeeId}</td>
                </tr>
                <tr>
                    <td>{t('summary.VALID_FROM')}</td>
                    <td><Format value={balanceResponse?.summary?.validFrom} format="DD MMMM YYYY" /></td>
                </tr>
                <tr>
                    <td>{t('summary.VALID_TILL')}</td>
                    <td><Format value={balanceResponse?.summary?.validTo} format="DD MMMM YYYY" /></td>
                </tr>
                <tr>
                    <td>{t('summary.CURRENCY')}</td>
                    <td>{balanceResponse?.summary?.currency}</td>
                </tr>
                <tr>
                    <td>{t('summary.MAX_BALANCE')}</td>
                    <td><Format value={balanceResponse?.summary?.maxBalance} currency="CHF" /></td>
                </tr>
            </tbody>
        </table>
    );

    return (
        <div className="container main">
            {errorResponse ? <Message value={errorResponse} error={true} /> : renderBalanceTable()}
        </div >
    );
};

export default BalanceTable;