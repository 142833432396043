import Format from '../components/Format';
import { useTranslation } from 'react-i18next';

const TransactionDetails = (props: any) => {
    const { t } = useTranslation()
    return props.detailTransaction ? (
        <div id="detailsPopup" className="overlay">
            <div className="popup">
                <button type="button" className="btn-close" onClick={e => props.setDetailTransaction(undefined)}>
                    <span className="icon-cross"></span>
                    <span className="visually-hidden">Close</span>
                </button>
                <div className="content">
                    <table className="table">
                        <caption>{props.detailTransaction?.till} - <Format value={props.detailTransaction?.date} format="DD MMMM YYYY HH:mm:ss" /></caption>
                        <thead>
                            <tr>
                                <th>{t('transactions.QUANTITY')}</th>
                                <th>{t('transactions.DESCRIPTION')}</th>
                                <th>{t('transactions.PRICE')}</th>
                                <th>{t('transactions.DISCOUNT')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.detailTransaction?.products?.map((product: Product, j: number) => (
                                    <tr key={j}>
                                        <td><Format value={product?.quantity} fractionDigits={3} /></td>
                                        <td>{product?.description}</td>
                                        <td><Format value={product?.price} currency="CHF" showzero={true} /></td>
                                        <td><Format value={product?.discount} currency="CHF" showzero={true} /></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    ) : (<div></div>);
}

export default TransactionDetails;