import dayjs from 'dayjs';

const formatDate = (props: any) => {
    return props.value ? dayjs(props.value).format(props.format) : "";
};

const formatNumber = (props: any) => {
    const formatNumber = props.value ? props.value : 0;
    return props.showzero || formatNumber !== 0 ? new Intl.NumberFormat("de-DE", {
        style: props.currency ? "currency" : "decimal",
        currency: props.currency,
        minimumFractionDigits: props.fractionDigits,
        maximumFractionDigits: props.fractionDigits
    }).format(formatNumber) : "";
}

const Format = (props: any) => ((typeof props.value) === "number" ? formatNumber(props) : formatDate(props))

export default Format;