import { useRoutes } from 'react-router-dom'
import Balance from './Balance'
import Transactions from './Transactions'

const Routes = (appConfig: AppConfig) => {
    const balance_routes = {
        path: "/",
        element: <Balance {...appConfig} />
    }

    const transactions_routes = {
        path: "/transactions",
        element: <Transactions {...appConfig} />
    }
    const routing = useRoutes([balance_routes, transactions_routes]);
    return (
        <>
            { routing }
        </>
    )
};

export default Routes;