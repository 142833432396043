import { useState } from 'react';
import { renderToString } from 'react-dom/server'
import dayjs from 'dayjs';
import AjaxCall from '../components/AjaxCall';
import Format from '../components/Format';
import Message from '../components/Message';
import TransactionDetails from '../components/TransactionDetails';
import DateFilter from '../components/DateFilter';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import xlsIcon from '../assets/xls-icon.svg';
import { useTranslation } from 'react-i18next';

const TransactionsTable = (appConfig: AppConfig) => {
  dayjs.extend(require('dayjs/plugin/utc'));
  dayjs.extend(require('dayjs/plugin/customParseFormat'));

  const [dateRange, setDateRange] = useState<Date[]>([dayjs().startOf("month").toDate(), dayjs().endOf("month").toDate()]);
  const [startDate, endDate] = dateRange;
  const { t } = useTranslation()

  let credit = 0.0;
  let debit = 0.0;

  const renderTransactionsTable = (exportTable: boolean) => {
    const xlsFileName = "Transactions_" + renderToString(<Format value={startDate} format={"DD-MM-YYYY"} />) + "_" + renderToString(<Format value={endDate} format={"DD-MM-YYYY"} />);
    return !transactionResponse?.transactions || transactionResponse?.transactions?.length === 0 ?
      !exportTable && transactionResponse && <Message value={t('transactions.NO_TRANSACTIONS')} /> :
      (
        <div>
          <table className={exportTable ? "export-table" : "table"} id={exportTable ? "transactionsExportTable" : "transactionsTable"}>
            <thead>
              <tr>
                <th>{t('transactions.DATE_TIME')}</th>
                <th>{t('transactions.TILL')}</th>
                <th>{t('transactions.CREDIT')}</th>
                <th>{t("transactions.DEBIT")}</th>
                <th>{t('transactions.BALANCE')}</th>
                {exportTable && (<th>{t('transactions.QUANTITY')}</th>)}
                {exportTable && (<th>{t('transactions.DESCRIPTION')}</th>)}
                {exportTable && (<th>{t('transactions.PRICE')}</th>)}
                {exportTable && (<th>{t('transactions.DISCOUNT')}</th>)}
                {!exportTable && (
                  <th>
                    <span className="linkimg" style={{ textDecoration: "none" }} onClick={e => document.getElementById("exportXlsButton")?.click()}><img src={xlsIcon} alt="Download Excel" title="Download Excel" /></span>
                    <ReactHTMLTableToExcel
                      id="exportXlsButton"
                      className="export-table"
                      table="transactionsExportTable"
                      filename={xlsFileName}
                      sheet={xlsFileName}>
                    </ReactHTMLTableToExcel>
                  </th>)}
              </tr>
            </thead>
            <tbody>
              {
                transactionResponse?.transactions?.map((trx: Transaction, i: number) => {
                  if (!exportTable) {
                    credit += parseFloat(trx?.credit);
                    debit += parseFloat(trx?.debit);
                  }
                  const trxRows = [
                    <tr key={i}>
                      <td><Format value={trx?.date} format={"DD MMMM YYYY HH:mm:ss"} /></td>
                      <td>{trx?.till}</td>
                      <td>{exportTable ? trx?.credit : <Format value={trx?.credit} currency="CHF" />}</td>
                      <td>{exportTable ? trx?.debit : <Format value={trx?.debit} currency="CHF" showzero={!trx?.credit} />}</td>
                      <td>{exportTable ? trx?.balance : <Format value={trx?.balance} currency="CHF" />}</td>
                      {!exportTable && trx?.products?.length !== 0 && (<td><span className="link" onClick={e => { e.preventDefault(); setDetailTransaction(trx); }} title="Show the transaction details">{t('transactions.DETAILS')}</span></td>)}
                    </tr>,
                  ]
                  if (exportTable) {
                    trx?.products?.map((product: Product, j: number) => trxRows.push(
                      <tr key={j}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{product?.quantity}</td>
                        <td>{product?.description}</td>
                        <td>{product?.price}</td>
                        <td>{product?.discount}</td>
                      </tr>
                    ))
                  }
                  return trxRows;
                })
              }
              {!exportTable && (
                <tr>
                  <td><b>{t('transactions.TOTAL')}</b></td>
                  <td></td>
                  <td><b><Format value={credit} currency="CHF" showzero={true} /></b></td>
                  <td><b><Format value={debit} currency="CHF" showzero={true} /></b></td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )
  };

  const [transactionResponse, setTransactionResponse] = useState<TransactionResponse>();
  const [detailTransaction, setDetailTransaction] = useState<Transaction>();
  const [errorResponse, setErrorResponse] = useState<any>();

  AjaxCall({
    url: appConfig.transactionsUrl,
    params: appConfig.userAttributes?.employeeId ? {
      employeeId: appConfig.userAttributes.employeeId,
      dateFrom: dayjs(startDate).utc().format(),
      dateTo: dayjs(endDate).utc().format()
    } : {
      dateFrom: dayjs(startDate).utc().format(),
      dateTo: dayjs(endDate).utc().format()
    },
    appConfig: appConfig,
    initHandler: () => {
      if (startDate && endDate) {
        setTransactionResponse(null);
        setDetailTransaction(null);
        setErrorResponse(null);
        return true;
      }
      return false;
    },
    successHandler:
      (response: any) => setTransactionResponse(response.data),
    errorHandler:
      (error: any) => setErrorResponse(error.response ? error.response.data ? error.response.data : error.response : error),
    dependencies: [dateRange]
  });

  return (
    <div className="container main">
      {!errorResponse && <DateFilter startDate={startDate} endDate={endDate} setDateRange={setDateRange} />}
      {!errorResponse && <TransactionDetails detailTransaction={detailTransaction} setDetailTransaction={setDetailTransaction} />}
      {errorResponse ? <Message value={errorResponse} error={true} /> : renderTransactionsTable(true)}
      {!errorResponse && renderTransactionsTable(false)}
    </div>
  );
}

export default TransactionsTable;