import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    nonExplicitSupportedLngs: false,
    supportedLngs: ["en", "de", "fr", "it"],
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['cookie', 'queryString', 'navigator'],
      cache: ['cookie']
    },

  });

