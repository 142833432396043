const Message = (props: any) => {
    const formatJson = (json: any) => {
        return JSON.stringify(json, null, 2);
    }

    return (
        <div className="message" style={{ fontFamily: "'Courier New', monospace", backgroundColor: props.error ? "#ffd1dc" : "#afe1af" }}>
            <pre>{typeof props.value === "string" ? props.value : formatJson(props.value)}</pre>
        </div>
    )
}
export default Message;