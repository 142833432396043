import DatePicker from 'react-datepicker';
import { forwardRef, PropsWithoutRef, LegacyRef } from 'react';
import dayjs from 'dayjs';

const DateInput = forwardRef((props: PropsWithoutRef<any>, refer: LegacyRef<any>) => (
    <div className="date-input">
        <input
            readOnly
            type="text"
            className={props.className}
            value={props.value}
            ref={refer}
        />
        <button className="icon icon-calendar icon--s3" onClick={props.onClick} onChange={props.onChange} title="Transactions are filtered according to this date range" />
    </div>
));

const DateFilter = (props: any) => (
    <div className="col-xs-12 col-lg-6 margin-bottom-2">
        <div className="margin-bottom-1">Date Range</div>
        <DatePicker
            className="input flatpickr-input form-control"
            onChange={(dateRange: Date[]) => props.setDateRange(dateRange)}
            customInput={<DateInput />}
            dateFormat="dd/MM/yyyy"
            startDate={props.startDate}
            endDate={props.endDate}
            selectsRange={true}
            minDate={dayjs().subtract(6, 'year').startOf("year").startOf("month").toDate()}
            maxDate={dayjs().toDate()}
        />
    </div>
);

export default DateFilter;