const Footer = () => {
    return (
        <footer className="footer footer--compact footer--light">
            <div className="container">
                <a href="/" className="logo" tabIndex={-1}>
                    <span className="logo-lifeform" aria-label="Swisscom Logo"></span>
                </a>
                <ul className="nav-items">
                    <span className="sc-navy">  &#169; Swisscom {new Date().getFullYear()}</span>
                </ul>
            </div>
        </footer>
    )
}

export default Footer;